import { useEffect, useState, useRef } from "react";

import GoogleMapReact from "google-map-react";
import axios from "axios";

import "./App.css";

function App() {
    const mapInstance = useRef();
    const mapsInstance = useRef();

    const [mapCenter, setMapCenter] = useState({
        lat: 51.50809,
        lng: -0.1302322,
    });

    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");

    const [locations, setLocations] = useState();
    const [since, setSince] = useState();

    useEffect(() => {
        fetchLocations();
    }, []);

    useEffect(() => {
        const interval = setInterval(fetchLocations, 5000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    async function fetchLocations() {
        setSince(async (oldSince) => {
            const locationsResponse = await axios.get(
                `${process.env.REACT_APP_API_URL}/location_events_public/journey`,
                {
                    params: {
                        token,
                        since: await oldSince,
                    },
                }
            );
            const fetchedLocations = locationsResponse.data;
            if (fetchedLocations?.length > 0) {
                const newSince =
                    fetchedLocations[fetchedLocations.length - 1].id;
                setLocations((oldLocations) => {
                    let newLocations = [];
                    if (Array.isArray(oldLocations)) {
                        newLocations = [...oldLocations, ...fetchedLocations];
                    } else {
                        newLocations = fetchedLocations;
                    }
                    const sortedLocations = newLocations.sort(
                        (locationA, locationB) =>
                            locationA.time - locationB.time
                    );
                    setMapCenter(
                        convertLocationToGoogleFormat(
                            sortedLocations[sortedLocations.length - 1].location
                        )
                    );
                    return sortedLocations;
                });
                return newSince;
            } else return oldSince;
        });
    }

    function convertLocationToGoogleFormat(location) {
        return {
            lat: location.lat,
            lng: location.lon,
        };
    }

    console.log("locations", locations);

    useEffect(() => {
        if (locations && mapsInstance.current) {
            var route = new mapsInstance.current.Polyline({
                path: locations.map((location) =>
                    convertLocationToGoogleFormat(location.location)
                ),
                strokeColor: "#25AA73",
                strokeWeight: 4,
            });
            route.setMap(mapInstance.current);
        }
    }, [locations, mapInstance.current]);

    function googleMapsAPILoaded(map, maps) {
        mapInstance.current = map;
        mapsInstance.current = maps;
    }

    return (
        <div className="App">
            <div className="container">
                <div className="headingContainer">
                    <h1 className="heading">Journey Progress</h1>
                    <img src={`${process.env.PUBLIC_URL}/logo_cropped.png`} />
                </div>
                <div className="mapContainer">
                    <GoogleMapReact
                        bootstrapURLKeys={{
                            key: "AIzaSyAv-Q-dREThRd3Y9TAqWS_XsF474_6-OgU",
                        }}
                        center={mapCenter}
                        zoom={13}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) =>
                            googleMapsAPILoaded(map, maps)
                        }
                        onChange={({ center }) => setMapCenter(center)}
                    ></GoogleMapReact>
                </div>
            </div>
        </div>
    );
}

export default App;
